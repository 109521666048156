import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { TextGradient } from 'src/components/text-gradient';
// routes
import { RouterLink } from 'src/routes/components';
// _mock
import { _socials } from 'src/_mock';
// components
import { useGetPageQuery, useLazyPostTrackQuery } from 'src/redux/services/salesrush/endpoints';
import Iconify from 'src/components/iconify';
import { useTheme } from '@emotion/react';
import { formatPhoneNumber } from 'src/utils/phone-format';
import moment from 'moment';

// ----------------------------------------------------------------------


export default function Footer() {

  // const { user } = useSelector(state => state.page);

  const [ trigger ] = useLazyPostTrackQuery();

  const [ subdomain ] = useState(window.location.host.split("."));

  const { data, isLoading } = useGetPageQuery(subdomain[0]);
  
  const isHome = true;
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  const contact_name = data?.user && data.user.user_level === 'salesperson' ? `${data.user.firstname} ${data.user.lastname}` : data?.user.company_name || '';
  const contact_phone = data?.user && data.user.user_level === 'salesperson' ? data.user.cell_phone_number : data?.user.office_number || '';

  const LINKS = [
    {
      headline: 'Legal',
      children: [
        { name: 'Terms and Condition', href: '#' },
        { name: 'Privacy Policy', href: '#' },
      ],
    },
    {
      headline: 'Contact',
      children: [{ name: formatPhoneNumber(contact_phone), href: `tel:${contact_phone}` }],
    },
  ];

  const track = async (data_track) => {

    const subdomain = window.location.host.split(".");

    const data_ = {
      url: data_track,
      page: data_track,
      subdomain: subdomain[0],
      f_date: moment().format('MM-YYYY'),
      date: moment().format()
    };

    await trigger(data_).unwrap();
  }
  

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container
        sx={{
          pt: 10,
          pb: 5,
          textAlign: { xs: 'center', md: 'unset' },
        }}
      >
        <Box justifyContent={{
            xs: 'center',
            md: 'flex-start',
          }}  xs={12} md={6} sx={{ height: 1, display: 'flex' }} >
          <TextGradient
            sx={{
              ml: 0,
              mr: 0,
            }}
            animate={{ backgroundPosition: '200% center' }}
            transition={{
              repeatType: 'reverse',
              ease: 'linear',
              duration: 20,
              repeat: Infinity,
            }}
            title={contact_name}
          />
        </Box>
        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
        >
          <Grid xs={8} md={3}>
            <Typography
              variant="body2"
              sx={{
                maxWidth: 270,
                mx: { xs: 'auto', md: 'unset' },
              }}
            >
              Welcome to the future of real estate! Our high-tech company is dedicated to revolutionizing your property experience. We&apos;re here to redefine the way you buy, sell, and invest in real estate. Elevate your property journey with us – where technology meets home like never before.
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{
                mt: 3,
                mb: { xs: 5, md: 0 },
              }}
            >
              {_socials.map((social) => (  
                <>
                {data?.user && data.user[social.value] && (  
                  <Link
                  onClick={() => track(social.value)}
                  key={data.user[social.value]}
                  href={data.user[social.value]}
                  variant="body2"
                  target="_blank"
                  rel="noopener"
                  sx={{ display: 'table' }}
                >             
                  <IconButton                   
                    key={social.name}
                    sx={{
                      '&:hover': {
                        bgcolor: alpha(social.color, 0.08),
                      },
                    }}
                  >
                    <Iconify color={social.color} icon={social.icon} />
                  </IconButton>
                </Link>
                )}
                </>
              ))}
            </Stack>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{
                mt: 3,
                mb: { xs: 5, md: 0 },
                alignItems: "center"
              }}
            >
              <Box >
                <img src={isLight ? '/logo/logo_bottom.webp' : '/logo/logo_white_bottom.webp'} alt="logo" height={70}/>
              </Box>
              <Box sx={{ ml: 3 }} >
                <img  src={isLight ? '/logo/r_logo.png' : '/logo/r_logo_w.png'} alt="logo" height={53}/>
              </Box>
            </Stack>

          </Grid>

          <Grid xs={12} md={6}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }}>
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                  sx={{ width: 1 }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      onClick={() => track("phone")}
                      key={link.name}
                      component={RouterLink}
                      href={link.href}
                      color="inherit"
                      variant="body2"
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>

          
        </Grid>

        <Typography variant="body2" sx={{ mt: 10 }}>
          © 2023. All rights reserved
        </Typography>
      </Container>
    </Box>
  );

  return isHome ? mainFooter : mainFooter;
}
